'use client';

import { SanityImage } from 'sanity-image';

type CustomImageProps = {
  image: any;
  width: number;
  height?: number;
  mode?: 'cover' | 'contain';
  alt?: string;
  className?: string;
  sizes?: string;
  blur?: boolean;
  aspectRatio?: string; // must be 'x/y' e.g. '1/1' '6/7' '3/4'
  loading?: 'eager' | 'lazy';
};

export type CustomImageWrapperProps = {
  image: any;
  alt?: string;
  className?: string;
};

/**
 * Sizes = the relevant percentages for the different layouts at breakpoints followed by 100vw
 * Width = max width ever, i.e. if constrained always then largest constrained size. If should be huge image on huge screens then give no width
 * e.g.
 * sizes = (min-width: 600px) 50vw, (min-width: 782px) 33vw, (min-width: 1280px) 60vw, 100vw
 * width= 768px
 *
 * @param image
 * @param width
 * @param height
 * @param mode
 * @param alt
 * @param className
 * @param sizes
 * @param blur
 * @param aspectRatio
 * @param loading
 * @constructor
 */

function CustomImage({
  image,
  width,
  height,
  mode = 'cover',
  alt,
  className,
  sizes,
  blur = false,
  aspectRatio,
  loading = 'lazy',
}: CustomImageProps) {
  if (image === undefined) {
    return null;
  }
  const id = image?.id ?? image.asset?._ref ?? image._ref;
  if (id === undefined) {
    return null;
  }

  const { crop, preview, hotspot, caption } = image;
  // let caption = undefined;
  let aspectMultiplier = undefined;
  let aspectHeight = undefined;
  if (aspectRatio !== undefined) {
    aspectMultiplier =
      Number(aspectRatio.split('/')[1]) / Number(aspectRatio.split('/')[0]);
    aspectHeight = Math.trunc(width * aspectMultiplier);
  }

  return (
    <SanityImage
      // Pass the Sanity Image ID (`_id`) (e.g., `image-abcde12345-1200x800-jpg`)
      id={id}
      //
      // You can set the base URL manually, or let it be constructed by passing
      // `projectId` and `dataset` props.
      projectId={`${process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}`}
      dataset={`${process.env.NEXT_PUBLIC_SANITY_DATASET}`}
      //
      // Specify how big it is expected to render so a reasonable srcSet can be
      // generated using `width`, `height`, or both
      width={width}
      height={height ?? aspectHeight ?? undefined}
      //
      // Choose whether you want it to act like `object-fit: cover` or
      // `object-fit: contain`, or leave it out to use the default (contain)
      mode={mode}
      //
      // Have hotspot or crop data from Sanity? Pass it in!
      hotspot={hotspot ?? undefined}
      crop={crop ?? undefined}
      //
      // Want low-quality image previews? Fetch them from Sanity and pass them in too.
      preview={blur && preview ? preview : undefined}
      //
      // Have a burning desire to have Sanity change the format or something?
      // Most of the visual effects from the Sanity Image API are available:
      // queryParams={{ sharpen: 30, q: 80 }}
      //
      // Anything else you want to pass through to the img tag? Go for it!
      alt={alt ?? caption ?? ''}
      className={className}
      sizes={sizes} // "(max-width: 500px) 500px, 100vw"
      loading={loading}
    />
  );
}

export default CustomImage;
