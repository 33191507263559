'use client';

import type { ProjectSchema } from '@/sanity/schemas/documents/project';
import useToggleableArray from '@/hooks/useToggleableArray';
import { ProjectCard } from '@/components/functional/project/ProjectCard';
import FilterIcon from '@/components/elements/icons/FilterIcon';

type Props = {
  projects: ProjectSchema[];
  projectTypes: string[];
};

function FilterableProjects({ projects, projectTypes }: Props) {
  const [filterBy, toggleFilterBy] = useToggleableArray([]);

  const filteredProjects = filterBy.length
    ? projects.filter((project) => {
        if (project?.projectTypes) {
          return project.projectTypes.some((r) => filterBy.includes(r));
        }
        return false;
      })
    : projects;

  let colsClass = '';
  if (projectTypes?.length > 0) {
    colsClass = 'grid-cols-' + String(Number(projectTypes.length) + 1);
  }
  return (
    <div className="FilterableProjects grid grid-cols-12 pt-80 lg:pt-160 xl:pt-300">
      <div
        className={
          'col-span-12 xl:col-start-2 xl:col-span-10 px-10 space-y-5 lg:space-y-10'
        }
      >
        <h2 className={'heading-h3'}>Sample Projects</h2>
        {colsClass !== '' ? (
          <div className={`flex flex-row gap-5 justify-center items-center`}>
            <FilterIcon className={'hidden xl:flex text-black mr-auto'} />
            <div className={'gap-5 flex flex-wrap'}>
              {projectTypes.map((projectType) => (
                <button
                  key={projectType}
                  onClick={() => toggleFilterBy(projectType)}
                  className={`btn-small rounded-full ${filterBy.includes(projectType) ? 'text-white bg-black shadow-teal-offset hover:!shadow-teal-offset' : ''}`}
                >
                  {projectType}
                </button>
              ))}
            </div>
          </div>
        ) : null}

        {/*<div className={'my-4'}>*/}
        {/*    <p>Filtering by: </p>*/}
        {/*    {filterBy.map(projectType => <span key={projectType} className="text-xss mr-2">{projectType}</span>)}*/}
        {/*</div>*/}

        {(filteredProjects || []).length ? (
          <div
            className={
              'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 2xl:gap-10'
            }
          >
            {filteredProjects.map((project) => (
              <ProjectCard key={project._id} project={project} />
            ))}
          </div>
        ) : (
          <p>No Projects Match</p>
        )}
      </div>
    </div>
  );
}

export default FilterableProjects;
