import { SlugSchemaType } from '@sanity/types/src';
import speakingurl from 'speakingurl';

function defaultSlugify(value: string, type?: SlugSchemaType) {
  const maxLength = (type?.options && type.options.maxLength) || 200;
  const slugifyOpts = { truncate: maxLength, symbols: true };
  return value ? speakingurl(value, slugifyOpts) : '';
}

function cleanSlashes(url: string): string {
  return url.replace(/\/\/+/g, '/').replace(/^\/|\/$/g, '');
}

/**
 * MAIN FUNCTION FOR CLEANING SLUGS - APPLY ANY SPECIFIC PROCESSING FUNCTIONS WITHIN THIS ONE.
 * @param url
 */
function cleanSlug(url: string): string {
  return cleanSlashes(url);
}

export { defaultSlugify, cleanSlug, cleanSlashes };
