import React from 'react';

const FilterIcon = (props) => {
  return (
    <svg
      {...props}
      width="51"
      height="36"
      viewBox="0 0 51 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51 1.8C51 2.27737 50.8082 2.73523 50.4665 3.07277C50.125 3.41046 49.6617 3.6 49.1786 3.6H1.82149C1.17073 3.6 0.56947 3.25688 0.244042 2.7C-0.0813473 2.1431 -0.0813473 1.4569 0.244042 0.9C0.569431 0.343104 1.17069 0 1.82149 0H49.1786C49.6616 0 50.1249 0.189561 50.4665 0.527232C50.8082 0.864729 51 1.32259 51 1.8ZM45.5357 16.2H5.46435C4.81359 16.2 4.21232 16.5431 3.88689 17.1C3.5615 17.6569 3.5615 18.3431 3.88689 18.9C4.21228 19.4569 4.81354 19.8 5.46435 19.8H45.5357C46.1865 19.8 46.7878 19.4569 47.1132 18.9C47.4386 18.3431 47.4386 17.6569 47.1132 17.1C46.7878 16.5431 46.1865 16.2 45.5357 16.2ZM33.6965 32.4H17.3036C16.6529 32.4 16.0516 32.7431 15.7262 33.3C15.4008 33.8569 15.4008 34.5431 15.7262 35.1C16.0516 35.6569 16.6528 36 17.3036 36H33.6965C34.3472 36 34.9485 35.6569 35.2739 35.1C35.5993 34.5431 35.5993 33.8569 35.2739 33.3C34.9485 32.7431 34.3473 32.4 33.6965 32.4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilterIcon;
