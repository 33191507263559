import React from 'react';

const MissingImageIcon = (props) => {
  return (
    <svg
      {...props}
      width="100"
      height="100"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill="currentColor"
          d="m84.375 6.25h-68.75c-5.1758 0-9.375 4.1992-9.375 9.375v68.75c0 5.1758 4.1992 9.375 9.375 9.375h68.75c5.1758 0 9.375-4.1992 9.375-9.375v-68.75c0-5.1758-4.1992-9.375-9.375-9.375zm-68.75 6.25h68.75c0.82812 0 1.625 0.32812 2.2109 0.91406 0.58594 0.58594 0.91406 1.3828 0.91406 2.2109v40.684c-0.25391-0.019532-0.50781-0.007813-0.75781 0.035156l-18.75 4.6875c-3.3789 0.84766-6.9453 0.53516-10.133-0.87891l-14.141-6.2852c-5.9727-2.6562-12.82-2.5-18.664 0.42578l-12.555 6.2773v-44.945c0-1.7266 1.3984-3.125 3.125-3.125zm68.75 75h-68.75c-1.7266 0-3.125-1.3984-3.125-3.125v-16.82l15.359-7.6797v0.003906c4.1719-2.0977 9.0664-2.2148 13.336-0.3125l14.137 6.2852c4.4609 1.9727 9.4531 2.4062 14.188 1.2305l17.98-4.4883v21.781c0 0.82812-0.32812 1.625-0.91406 2.2109-0.58594 0.58594-1.3828 0.91406-2.2109 0.91406z"
        />
        <path
          fill="currentColor"
          d="m65.625 46.875c5.0547 0 9.6133-3.0469 11.547-7.7148 1.9375-4.6719 0.86719-10.051-2.707-13.625-3.5742-3.5742-8.9531-4.6445-13.625-2.707-4.668 1.9336-7.7148 6.4922-7.7148 11.547 0 3.3164 1.3164 6.4961 3.6602 8.8398s5.5234 3.6602 8.8398 3.6602zm0-18.75c2.5273 0 4.8086 1.5234 5.7734 3.8594 0.96875 2.3359 0.43359 5.0234-1.3555 6.8086-1.7852 1.7891-4.4727 2.3242-6.8086 1.3555-2.3359-0.96484-3.8594-3.2461-3.8594-5.7734 0-3.4531 2.7969-6.25 6.25-6.25z"
        />
      </g>
    </svg>
  );
};

export default MissingImageIcon;
