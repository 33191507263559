import { createClient } from 'next-sanity';

const projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID!;
const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET!;

export const token = process.env.SANITY_API_READ_TOKEN;
export const useCdn: boolean = !!process.env.SANITY_CDN || true;

export const client = createClient({
  projectId: projectId ?? '123abc',
  dataset: dataset ?? 'production',
  apiVersion: '2024-02-10',
  useCdn: useCdn,
  perspective: 'published'
});
