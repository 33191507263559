const groqOGImgString = `...,
    "metadata": { 
        "dimensions": asset->metadata.dimensions 
    },
    alt
`;

const groqImgString = `"id": asset._ref,
    "preview": asset->metadata.lqip,
    "metadata": { "dimensions": asset->metadata.dimensions },
    hotspot { x, y },
    crop {
      bottom,
      left,
      right,
      top,
    },
    "caption": alt
`;

const portableTextLoadInternalLinksString = `
    ...,
    markDefs[]{
      ...,
      _type == "internalLink" => {
        "slug": @.reference->slug
      }
    }
`;

const groqExpandBlocksString = `
    ...,
    _type == 'textBlock' => {
      ...,
      body[] {
        ${portableTextLoadInternalLinksString}
      }
    },
    _type == 'cardRowsBlock' => {
        ...,
        body[]{
          ${portableTextLoadInternalLinksString}
        },
      cards[] {
        ...,
        body[]{
          ${portableTextLoadInternalLinksString}
        },
        target {
          ...,
          internal->
        }
      },
    },
    _type == 'cta' => {
      ...,
      target {
        ...,
        internal->
      },
    },
    _type == 'textWithImageBlock' => {
      ...,
      target {
        ...,
        internal->
      },
       body[]{
          ${portableTextLoadInternalLinksString}
        },
    },
    _type == 'introBlock' => {
      ...,
       body[]{
          ${portableTextLoadInternalLinksString}
        },
    },
    _type == 'introBlockWithImage' => {
      ...,
       body[]{
          ${portableTextLoadInternalLinksString}
        },
    },
    _type == 'relatedProjectsBlock' => {
      ...,
      projects[]->
    },
    _type == 'heroBlock' => {
      ...,
       body[]{
          ${portableTextLoadInternalLinksString}
        },
      ctas[]{
        _type == 'cta' => {
          ...,
          target {
            ...,
            internal->
          },
        },
      }
    },
    _type == 'formBlock' => {
      ...,
      'treatments': *[_type == 'treatment']
    }
`;

export {
  groqExpandBlocksString,
  groqOGImgString,
  groqImgString,
  portableTextLoadInternalLinksString,
};
