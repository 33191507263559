import { ProjectSchema } from '@/sanity/schemas/documents/project';
import MissingImageIcon from '@/components/elements/icons/MissingImageIcon';
import Link from 'next/link';
import ProjectCardImage from '@/components/functional/image/ProjectCardImage';
import ProjectCardHoverImage from '@/components/functional/image/ProjectCardHoverImage';

// Max image sizes
// up to 782 = 710px (1up)
// up to 960 = 430px (2up)
// upto 1280 = 390px (3up)
// upto 2560 = 660px (3up)

export function ProjectCard({ project }: { project: ProjectSchema }) {
  let projectUrl = '/';
  if (project?.slug?.current) {
    projectUrl += project?.slug?.current;
  }
  return (
    <Link
      href={projectUrl}
      className={'group block transition-all duration-300'}
    >
      <div
        className={
          'aspect-6/7 bg-black overflow-hidden flex flex-col place-items-center justify-center relative gap-0'
        }
      >
        {project?.listImage ? (
          <ProjectCardImage
            image={project.listImage}
            className={
              'opacity-100 lg:group-hover:opacity-50 duration-500 object-cover'
            }
          />
        ) : (
          <MissingImageIcon />
        )}
        {project?.listHoverImage ? (
          <div
            className={
              'hidden lg:block opacity-0 scale-95 absolute group-hover:opacity-100 group-hover:-translate-y-5 group-hover:scale-[99%] transition-all duration-500 left-5 right-5 lg:left-16 lg:right-16 2xl:left-20 2xl:right-20 3xl:right-32 3xl:left-32 -bottom-10 transform-gpu drop-shadow-xl rounded-xl overflow-hidden'
            }
          >
            <ProjectCardHoverImage image={project.listHoverImage} />
          </div>
        ) : null}
      </div>
      <div className={'flex flex-row justify-between py-3 gap-2 space-x-2.5'}>
        <h2 className={'text-2xs md:text-xs'}>
          <strong>{project.title}</strong>
          {project?.strapline ? (
            <span className={'inline md:hidden 2xl:inline'}>
              {' '}
              - {project.strapline}
            </span>
          ) : null}
        </h2>
      </div>
    </Link>
  );
}
