'use client';

import { useState } from 'react';

function useToggleableArray(initialArray?: string[]) {
  const [toggleableArray, setToggleableArray] = useState(
    initialArray || ([] as string[]),
  );

  const toggleArrayValue = (item: string) => {
    if (toggleableArray.includes(item)) {
      setToggleableArray(toggleableArray.filter((el) => el !== item));
    } else {
      setToggleableArray(toggleableArray.concat(item));
    }
  };

  return [toggleableArray, toggleArrayValue] as const;
}

export default useToggleableArray;
