import CustomImage, {
  CustomImageWrapperProps,
} from '@/components/functional/image/CustomImage';

/**
 * @param image SanityImage
 * @param alt string optional
 * @param className string optional
 * @constructor
 */
function ProjectCardImage({
  image,
  alt = '',
  className = '',
}: CustomImageWrapperProps) {
  return (
    <CustomImage
      image={image}
      alt={alt}
      width={710}
      aspectRatio={'6/7'}
      sizes={'(min-width: 782px) 50vw, (min-width: 960px) 33vw, 100vw'}
      mode="cover"
      className={className}
    />
  );
}

export default ProjectCardImage;
