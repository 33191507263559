import { client } from '@/sanity/sanity.client';
import { groq } from 'next-sanity';

import type { ProjectSchema } from '@/sanity/schemas/documents/project';
import type { PostSchema } from '@/sanity/schemas/documents/post';
import type { PageSchema } from '@/sanity/schemas/documents/page';
import type { HomePageSchema } from '@/sanity/schemas/documents/homePage';
import type { NavigationSchema } from '@/sanity/schemas/documents/navigation';
import type { SiteSettingsSchema } from '@/sanity/schemas/documents/siteSettings';
import { cleanSlug } from '@/sanity/schemas/utils/base';
import {
  groqExpandBlocksString,
  groqImgString,
  groqOGImgString,
  portableTextLoadInternalLinksString
} from '@/sanity/schemas/groqStrings';
import { TreatmentSchema } from '@/sanity/schemas/documents/treatment';

export const countOfDocumentTypeQuery = groq`count(*[_type == $documentType])`;

/**
 * Requires
 * params: {ids},
 */
export const projectsByRefQuery = groq`*[_type == "project" && _id in $ids]{
    _id,
    title,
    slug,
    seoTitle,
    metaDescription,
    noindex,
    opengraphImage{${groqOGImgString}},
    listImage{${groqImgString}},
    listHoverImage{${groqImgString}},
    projectTypes,
    strapline
}`;

export async function getProjectsByRefs(
  ids?: string[]
): Promise<ProjectSchema[]> {
  return client.fetch(projectsByRefQuery, { ids });
}

export const allProjectsQuery = groq`*[_type == "project"] {
    _id,
    _updatedAt,
    title,
    slug,
    seoTitle,
    metaDescription,
    noindex,
    opengraphImage{${groqOGImgString}},
    listImage{${groqImgString}},
    listHoverImage{${groqImgString}},
    projectTypes,
    strapline
}`;

export async function getAllProjects(): Promise<ProjectSchema[]> {
  return client.fetch(allProjectsQuery);
}

/**
 * Requires
 * params: {slug: cleanSlug(slug)}
 */
export const projectQuery = groq`*[_type == "project" && slug.current == $slug][0]{
  ...
  _id,
  _createdAt,
  title,
  slug,
  blocks[] {${groqExpandBlocksString}},
}`;

export function getProjectQuery(): string {
  return projectQuery;
}

export async function getProject(slug: string): Promise<ProjectSchema> {
  return client.fetch(getProjectQuery(), { slug: cleanSlug(slug) });
}

export const getPostsPaginatedQueryAndParams = function(
  page = 1,
  perPage = 20
) {
  return {
    query: groq`*[_type == "post"] | order(_createdAt desc) [$from...$to] {
            _id,
            title,
             slug,
             categories[]->{...},
            featuredImage{${groqImgString}}
        }`,
    params: {
      from: (page - 1) * perPage,
      to: page * perPage
    },
    tags: []
  };
};

export const postsQuery = groq`*[_type == "post"] {
    _id,
    _updatedAt,
    title,
    slug,
    categories[]->{...},
    seoTitle,
    metaDescription,
    noindex,
    opengraphImage{${groqOGImgString}},
    featuredImage{${groqImgString}}
}`;

export const treatmentsQuery = groq`*[_type == "treatment"] {
    _id,
    _updatedAt,
    title,
    slug,
    price,
    description,
    categories[]->{...},
    seoTitle,
    metaDescription,
    noindex,
    opengraphImage{${groqOGImgString}},
    featuredImage{${groqImgString}}
}`;

export async function getPosts(): Promise<PostSchema[]> {
  return client.fetch(postsQuery);
}

export async function getTreatments(): Promise<TreatmentSchema[]> {
  return client.fetch(treatmentsQuery);
}

/**
 * Requires
 * params: {slug: cleanSlug(slug)
 */
export const postQuery = groq`*[_type == "post" && slug.current == $slug][0] {
  _id,
  _createdAt,
  title,
  body[] {
    ${portableTextLoadInternalLinksString}
  },
  slug,
  categories[]->{...},
  seoTitle,
  metaDescription,
  noindex,
  opengraphImage{${groqOGImgString}},
  featuredImage{${groqImgString}}
}`;

export const treatmentQuery = groq`*[_type == "treatment" && slug.current == $slug][0] {
  _id,
  _createdAt,
  title,
  body[] {
    ${portableTextLoadInternalLinksString}
  },
  description,
  slug,
  categories[]->{...},
  seoTitle,
  metaDescription,
  noindex,
  opengraphImage{${groqOGImgString}},
  featuredImage{${groqImgString}}
}`;

export function getPostQuery(): string {
  return postQuery;
}

export async function getPost(slug: string): Promise<PostSchema> {
  return client.fetch(postQuery, { slug: cleanSlug(slug) });
}

export const pagesQuery = groq`*[_type == "page"] {
  _id,
  _createdAt,
  title,
  slug,
  seoTitle,
  metaDescription,
  noindex,
  opengraphImage{${groqOGImgString}}
}`;

export async function getPages(): Promise<PageSchema[]> {
  return client.fetch(pagesQuery);
}

/**
 * Requires
 * params:{slug: cleanSlug(slug)}
 */
export const pageQuery = groq`*[_type == "page" && slug.current == $slug][0]{
  _id,
  _createdAt,
  title,
  slug,
  blocks[] {${groqExpandBlocksString}},
  heroBlocks[] {${groqExpandBlocksString}},
  seoTitle,
  metaDescription,
  noindex,
  opengraphImage{${groqOGImgString}}
}`;

export function getPageQuery(): string {
  return pageQuery;
}

export async function getPage(slug: string): Promise<PageSchema> {
  return client.fetch(pageQuery, { slug: cleanSlug(slug) });
}

export const homePageQuery = groq`*[_type == "homePage"][0] {
  _id,
  _createdAt,
  title,
  blocks[] {${groqExpandBlocksString}},
  heroBlocks[] {${groqExpandBlocksString}},
  seoTitle,
  metaDescription,
  noindex,
  opengraphImage{${groqOGImgString}}
}`;

export function getHomePageQuery(): string {
  return homePageQuery;
}

export async function getHomePage(): Promise<HomePageSchema> {
  return await client.fetch(getHomePageQuery(), {});
}

/**
 * Requires
 * params: {name}
 */
export const navigationQuery = groq`*[_type == "navigation" && name == $name][0] {
    ...,
    sections[] {
      ...,
      title,
      target{
         title,             
         internal->{
            _id,
            slug{...},
            title
         },             
         external
      },
      sections[]{
        ...,
        title,
        target{
          title,             
          internal->{
            _id,
            slug{...},
            title
          },                   
          external
        },
      }
    }
  }`;

export async function getNavigation(name: string): Promise<NavigationSchema> {
  return await client.fetch(navigationQuery, { name });
}

export const siteSettingsQuery = groq`*[_type == "siteSettings"][0] {
    title,
    description,
    googleanalyticsId,
    logo {
      asset->{
        ...,
        metadata
      },
      ...
    },
    opengraphImage{${groqOGImgString}},
    telephone,
    addressLine1,
    addressLine2,
    city,
    county,
    postcode,
    email,
    location,
    facebook,
    messenger,
    linkedIn,
    instagram
  }`;

export async function getSiteSettings(): Promise<SiteSettingsSchema> {
  return await client.fetch(siteSettingsQuery);
}

export async function getImageAsset(ref: string) {
  return client.fetch(
    groq`*[_type == "sanity.imageAsset" && _id== $ref][0] { ... }`,
    { ref }
  );
}

export async function getByReference(ref: string) {
  return client.fetch(groq`*[_id== $ref][0] { ... }`, { ref });
}
